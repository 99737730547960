import { all } from 'redux-saga/effects';

import watchAuth from 'providers/AuthProvider/saga';
import watchCategory from 'providers/CategoryProvider/saga';
import watchConversation from 'providers/ConversationProvider/saga';
import watchGenre from 'providers/GenreProvider/saga';
import watchInvoice from 'providers/InvoiceProvider/saga';
import watchLiveQuery from 'providers/LiveQueryProvider/saga';
import watchManufacturer from 'providers/ManufacturerProvider/saga';
import watchMenu from 'providers/MenuProvider/saga';
import watchNotification from 'providers/NotificationProvider/saga';
import watchOrder from 'providers/OrderProvider/saga';
import watchPrefecture from 'providers/PrefectureProvider/saga';
import watchRestaurant from 'providers/RestaurantProvider/saga';
import watchShippingCompany from 'providers/ShippingCompanyProvider/saga';
import watchAnnouncement from 'providers/AnnouncementProvider/saga';
import watchSupplier from 'providers/SupplierProvider/saga';
import watchBrand from 'providers/BrandProvider/saga';
import watchBrandOwner from 'providers/BrandOwnerProvider/saga';
import watchSupplierBrand from 'providers/SupplierBrandProvider/saga';
import watchGuestInformation from 'providers/GuestInformationProvider/saga';
import watchRestaurantOwner from 'providers/RestaurantOwnerProvider/saga';
import watchEmailInvitation from 'providers/EmailInvitationProvider/saga';
import watchCollection from 'providers/CollectionProvider/saga';
import watchRelatedProductProvider from 'providers/RelatedProductProvider/saga';
import watchRelationBetweenRestaurantAndRestaurantOwner from 'providers/RelationBetweenRestaurantAndRestaurantOwnerProvider/saga';
import watchRelationBetweenRestaurantAndSupplier from 'providers/RelationBetweenRestaurantAndSupplierProvider/saga';
import watchRanking from 'providers/RankingProvider/saga';
import watchBanner from 'providers/BannerProvider/saga';
import watchInAppMessage from 'providers/InAppMessageProvider/saga';
import watchRestaurantGroup from 'providers/RestaurantGroupProvider/saga';

export default function* rootSaga() {
  yield all([
    watchAuth(),
    watchCategory(),
    watchConversation(),
    watchGenre(),
    watchInvoice(),
    watchLiveQuery(),
    watchManufacturer(),
    watchMenu(),
    watchNotification(),
    watchOrder(),
    watchPrefecture(),
    watchRestaurant(),
    watchShippingCompany(),
    watchAnnouncement(),
    watchSupplier(),
    watchBrand(),
    watchBrandOwner(),
    watchSupplierBrand(),
    watchGuestInformation(),
    watchRestaurantOwner(),
    watchEmailInvitation(),
    watchCollection(),
    watchRelatedProductProvider(),
    watchRelationBetweenRestaurantAndRestaurantOwner(),
    watchRelationBetweenRestaurantAndSupplier(),
    watchRanking(),
    watchBanner(),
    watchInAppMessage(),
    watchRestaurantGroup(),
  ]);
}
