import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { connectRouter } from 'connected-react-router';

import authProvider from 'providers/AuthProvider/reducer';
import notificationProvider from 'providers/NotificationProvider/reducer';
import conversationProvider from 'providers/ConversationProvider/reducer';
import restaurantProvider from 'providers/RestaurantProvider/reducer';
import prefectureProvider from 'providers/PrefectureProvider/reducer';
import menuProvider from 'providers/MenuProvider/reducer';
import orderProvider from 'providers/OrderProvider/reducer';
import categoryProvider from 'providers/CategoryProvider/reducer';
import invoiceProvider from 'providers/InvoiceProvider/reducer';
import genreProvider from 'providers/GenreProvider/reducer';
import manufacturerProvider from 'providers/ManufacturerProvider/reducer';
import shippingCompanyProvider from 'providers/ShippingCompanyProvider/reducer';
import commonProvider from 'providers/CommonProvider/reducer';
import announcementProvider from 'providers/AnnouncementProvider/reducer';
import supplierProvider from 'providers/SupplierProvider/reducer';
import brandProvider from 'providers/BrandProvider/reducer';
import brandOwnerProvider from 'providers/BrandOwnerProvider/reducer';
import supplierBrandProvider from 'providers/SupplierBrandProvider/reducer';
import guestInformationProvider from 'providers/GuestInformationProvider/reducer';
import restaurantOwnerProvider from 'providers/RestaurantOwnerProvider/reducer';
import emailInvitationProvider from 'providers/EmailInvitationProvider/reducer';
import collectionProvider from 'providers/CollectionProvider/reducer';
import relatedProductProvider from 'providers/RelatedProductProvider/reducer';
import relationBetweenRestaurantAndRestaurantOwnerProvider from 'providers/RelationBetweenRestaurantAndRestaurantOwnerProvider/reducer';
import relationBetweenRestaurantAndSupplierProvider from 'providers/RelationBetweenRestaurantAndSupplierProvider/reducer';
import rankingProvider from 'providers/RankingProvider/reducer';
import bannerProvider from 'providers/BannerProvider/reducer';
import inAppMessageProvider from 'providers/InAppMessageProvider/reducer';
import restaurantGroupProvider from 'providers/RestaurantGroupProvider/reducer';

export default function createReducer(history) {
  return combineReducers({
    router: connectRouter(history),
    form: formReducer,

    authProvider,
    notificationProvider,
    conversationProvider,
    restaurantProvider,
    prefectureProvider,
    menuProvider,
    orderProvider,
    categoryProvider,
    invoiceProvider,
    genreProvider,
    manufacturerProvider,
    shippingCompanyProvider,
    commonProvider,
    announcementProvider,
    supplierProvider,
    brandProvider,
    brandOwnerProvider,
    supplierBrandProvider,
    guestInformationProvider,
    restaurantOwnerProvider,
    emailInvitationProvider,
    collectionProvider,
    relatedProductProvider,
    relationBetweenRestaurantAndRestaurantOwnerProvider,
    relationBetweenRestaurantAndSupplierProvider,
    rankingProvider,
    bannerProvider,
    inAppMessageProvider,
    restaurantGroupProvider,
  });
}
